<template>
  <div class="container">
    <div class="personal__title title">Личные данные</div>
    <form class="personal-form" @submit.prevent="sendRequest()" ref="form">
      <div class="personal-form__item">
        <div class="personal-form__input">
          <FormText
            :label="'Новый пароль *'"
            :type="'password'"
            v-model="request.password"
          />
        </div>
      </div>

      <div class="personal-form__item">
        <div class="personal-form__input">
          <FormText
            :label="'Новый пароль еще раз *'"
            :type="'password'"
            v-model="request.confirm_password"
          />
        </div>
      </div>
      <div class="personal-form__item">
        <button class="btn personal-form__submit">Сохранить изменения</button>
      </div>
    </form>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";

export default {
  components: {
    FormText,
  },

  data() {
    return {
      request: {
        password: null,
        confirm_password: null,
      },
    };
  },

  computed: {
    message() {
      return this.$store.state.messageModalSettings.response;
    },
  },

  methods: {
    sendRequest() {
      this.$store.dispatch("AUTH_CHANGE_PASSWORD", this.request);
    },
  },
};
</script>
